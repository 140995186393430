<template>
    <div class="p-3 bg-gray-relative-100 mh-vh-100-nav">
        <SubHeader title="QR스캔"></SubHeader>
        <div>
            <h4>
                고객의 코드 또는 <br>고객의 QR코드를 인식하여 주세요.
            </h4>
            <ul class="small my-4">
                <li class="d-flex lh-sm mb-1"><span class="text-gray-relative-500">고객의 포인트가 부족할 경우 결제가 진행되지 않습니다.</span></li>
            </ul>
        </div>
        <div class="form-group mb-2 mt-5">
            <div class="d-flex ">
                <input class="form-control thick bg-body border-gray-relative-300" type="text" placeholder="고객코드" v-model="code">
                <div class="btn btn-secondary flex-shrink-0 small d-flex align-items-center ms-1"><span><i class="fal fa-qrcode"></i></span></div>
            </div>
        </div>
        <button class="rounded-3 btn thick btn-main w-100" @click="PointPayment()">결제</button>
        <Loading v-if="loading"/>            
    </div>
</template>

<script>
import SubHeader from '@/components/common/SubHeader.vue'
const CryptoJS = require("crypto-js");
import Loading from '@/components/common/Loading.vue'

export default {
    components: {
        SubHeader,Loading
    },
    data(){
        return{
            loading : false,
            code : '',
            payment_code: this.$route.params.code,
        }
    },
    methods:{
        PointPayment(){
            const code = this.code;
            const payment_code = this.payment_code;

            if(code ==''){
                this.$alert("고객 코드를 확인해주세요.");
                return false;
            }

            const body = {code,payment_code};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/shop/payment/PointPayment',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            this.$router.push({path : `/payment/receipt/${payment_code}`});
                        }else if(res.data.code=='100'){
                            this.$alert("입력한 고객정보와 다릅니다.")
                        }else if(res.data.code=='300'){
                            this.$alert("한도가 초과되었습니다.");
                        }else if(res.data.code=='9999'){
                            this.$store.dispatch('SETLOGOUT').then(
                                ()=>{
                                    window.location.href="/signin";
                                }
                            )
                        }
                    }
                }
            );
            
        },
        Payment(){
            if(false){ //한도초과시
                this.$alert("한도가 초과되었습니다.").then( ()=>{
                    this.$router.push('/home')
                } )
            }else{
                this.$router.push('/payment/receipt')
            }
        }
    }
}
</script>

<style>

</style>